// 验证规则
export const rules = {
  configName: [
    {
      required: true,
      message: "请输入视频名称",
      trigger: "blur",
    },
  ],
  videoId: [
    {
      required: true,
      message: "请输入视频id",
      trigger: "blur",
    },
  ],
  functionName: [
    {
      required: true,
      message: "请填写菜单名称",
      trigger: "blur",
    },
  ],
  sortWeight: [
    {
      required: true,
      message: "请填写排列序号",
      trigger: "blur",
    },
  ],
  userName: [
    {
      required: true,
      message: "请填写姓名",
      trigger: "blur",
    },
  ],
  mobile: [
    {
      required: true,
      message: "请填写联系方式",
      trigger: "blur",
    },
    {
      min: 11,
      max: 11,
      message: "请填写正确的手机号（长度为11位）",
      trigger: "blur",
    },
  ],
  roleIds: [
    {
      required: true,
      message: "请选择角色",
      trigger: "blur",
    },
  ],
  roleName: [
    {
      required: true,
      message: "请填写角色",
      trigger: "blur",
    },
  ],
  roleCode: [
    {
      required: true,
      message: "请填写角色标识",
      trigger: "blur",
    },
  ],
  sex: [
    {
      required: true,
      message: "请选择性别",
      trigger: "blur",
    },
  ],
  isAdmin: [
    {
      required: true,
      message: "请选择类型",
      trigger: "blur",
    },
  ],
  merchantName: [
    {
      required: true,
      message: "请填写商户名称",
      trigger: "blur",
    },
  ],
  telPhone: [
    {
      required: true,
      message: "请填写商户手机",
      trigger: "blur",
    },
    {
      min: 11,
      max: 11,
      message: "请填写正确的商户手机号（长度为11位）",
      trigger: "blur",
    },
  ],
  adminUserName: [
    {
      required: true,
      message: "请填写商户超管姓名",
      trigger: "blur",
    },
  ],
  legalPerson: [
    {
      required: true,
      message: "请填写商户法人",
      trigger: "blur",
    },
  ],
  districtId: [
    {
      required: true,
      message: "请选择地域",
      trigger: "blur",
    },
  ],
  businessLicenseFileId: [
    {
      required: true,
      message: "请上传营业执照",
      trigger: "blur",
    },
  ],
  shopName: [
    {
      required: true,
      message: "请填写店铺名称",
      trigger: "blur",
    },
  ],
  shopWeixinAccount: [
    {
      required: true,
      message: "请填写收款账户",
      trigger: "blur",
    },
  ],
  merchantId: [
    {
      required: true,
      message: "请选择所属商户",
      trigger: "blur",
    },
  ],
  shopType: [
    {
      required: true,
      message: "请选择商户类型",
      trigger: "blur",
    },
  ],
  serviceName: [
    {
      required: true,
      message: "请选择服务内容名称",
      trigger: "blur",
    },
  ],
  shopId: [
    {
      required: true,
      message: "请选择店铺",
      trigger: "blur",
    },
  ],
  serviceId: [
    {
      required: true,
      message: "请选择服务内容",
      trigger: "blur",
    },
  ],
  shopAddress: [
    {
      required: true,
      message: "请填写门店地址",
      trigger: "blur",
    },
  ],
  shopPhone: [
    {
      required: true,
      message: "请填写商家电话",
      trigger: "blur",
    },
  ],
  testMobile: [
    {
      min: 11,
      max: 11,
      message: "请填写正确的装修员手机号（长度为11位）",
      trigger: "blur",
    },
  ],
  businessHoursStart: [
    {
      required: true,
      message: "请选择营业开始时间",
      trigger: "blur",
    },
  ],
  businessHoursEnd: [
    {
      required: true,
      message: "请选择营业结束时间",
      trigger: "blur",
    },
  ],
  closeDownStatus: [
    {
      required: true,
      message: "请选择是否停业",
      trigger: "blur",
    },
  ],
  servicePicId: [
    {
      required: true,
      message: "请上传服务图标",
      trigger: "blur",
    },
  ],
  shopFileUrl: [
    {
      required: true,
      message: "请上传门头图片",
      trigger: "blur",
    },
  ],
  businessHoursEnd: [
    {
      required: true,
      message: "请选择停业起止日期",
      trigger: "blur",
    },
  ],
  stopDescribe: [
    {
      required: true,
      message: "请填写停用描述",
      trigger: "blur",
    },
  ],
  roomIds: [
    {
      required: true,
      message: "请选择房间",
      trigger: ["blur", "change"],
    },
  ],
  closeDownEnd: [
    {
      required: true,
      message: "请选择停业起止日",
      trigger: "blur",
    },
  ],
  closeTimeStart: [
    {
      required: true,
      message: "请选择停用起始时间",
      trigger: "blur",
    },
  ],
  closeTimeEnd: [
    {
      required: true,
      message: "请选择停用结束时间",
      trigger: "blur",
    },
  ],
  // TODO:编辑房间
  roomName: [
    {
      required: true,
      message: "请填写房间名称",
      trigger: "blur",
    },
    {
      min: 0,
      max: 20,
      message: "房间名称最多20个字",
      trigger: "blur",
    },
  ],
  price: [
    {
      required: true,
      message: "请填写房间价格",
      trigger: "blur",
    },
  ],
  priceDay: [
    {
      required: true,
      message: "请填写房间价格",
      trigger: "blur",
    },
  ],
  priceMonth: [
    {
      required: true,
      message: "请填写房间价格",
      trigger: "blur",
    },
  ],
  priceDescribe: [
    {
      required: false,
      trigger: "blur",
    },
    {
      min: 0,
      max: 6,
      message: "价格描述最多6个字",
      trigger: "blur",
    },
  ],
  discountInformation: [
    {
      required: false,
      trigger: "blur",
    },
    {
      min: 0,
      max: 16,
      message: "优惠信息最多16个字",
      trigger: "blur",
    },
  ],
  // TODO:高级定价
  timeSlotStart: [
    {
      required: true,
      message: "请选择起始时间",
      trigger: "blur",
    },
  ],
  timeSlotEnd: [
    {
      required: true,
      message: "请选择结束时间",
      trigger: "blur",
    },
  ],
  roomPrice: [
    {
      required: true,
      message: "请填写房间价格",
      trigger: "blur",
    },
  ],
  // 小程序配置
  wifiName: [
    {
      required: true,
      message: "请选填写wifi名称",
      trigger: "blur",
    },
  ],
  wifiPassword: [
    {
      required: true,
      message: "请选填写wifi密码",
      trigger: "blur",
    },
  ],
  roomId: [
    {
      required: true,
      message: "请选择房间",
      trigger: "blur",
    },
  ],
  appName: [
    {
      required: true,
      message: "请填写应用名称",
      trigger: "blur",
    },
  ],
  accountId: [
    {
      required: true,
      message: "请填写抖音来客账号ID",
      trigger: "blur",
    },
  ],
  appId: [
    {
      required: true,
      message: "请填写AppId",
      trigger: "blur",
    },
  ],
  apSecret: [
    {
      required: true,
      message: "请填写AppSecret",
      trigger: "blur",
    },
  ],
  labelName: [
    {
      required: true,
      message: "请填写标签名称",
      trigger: "blur",
    },
  ],
  cardIds: [
    {
      required: true,
      message: "请选择关联卡券",
      trigger: "blur",
    },
  ],
  // 新增卡券
  cardName: [
    {
      required: true,
      message: "请填写卡券名称",
      trigger: "blur",
    },
  ],
  background: [
    {
      required: true,
      message: "请选择卡券样式",
      trigger: "blur",
    },
  ],
  cardType: [
    {
      required: true,
      message: "请选择卡券类型",
      trigger: "blur",
    },
  ],
  preMinHourNum: [
    {
      required: true,
      message: "请选择连续时长",
      trigger: "blur",
    },
  ],
  cardTypeNum: [
    {
      required: true,
      message: "请填写可用次数",
      trigger: "blur",
    },
  ],
  preferentialPrice: [
    {
      required: true,
      message: "请填写卡券价格",
      trigger: "blur",
    },
  ],
  originalPrice: [
    {
      required: true,
      message: "请填写划线价格",
      trigger: "blur",
    },
  ],
  enableStock: [
    {
      required: true,
      message: "请选择卡券库存",
      trigger: "blur",
    },
  ],
  effType: [
    {
      required: true,
      message: "请选择有效期限",
      trigger: "blur",
    },
  ],
  afterDayNum: [
    {
      required: true,
      message: "请填写有效天数",
      trigger: "blur",
    },
  ],
  endTime: [
    {
      required: true,
      message: "请填写有效日期",
      trigger: "blur",
    },
  ],
  enablePreLimit: [
    {
      required: true,
      message: "请选择预约限制",
      trigger: "blur",
    },
  ],
  enableRenewalLimit: [
    {
      required: true,
      message: "请选择续单限制",
      trigger: "blur",
    },
  ],
  enableBuyLimit: [
    {
      required: true,
      message: "请选择购买数量",
      trigger: "blur",
    },
  ],
  buyMaxNum: [
    {
      required: true,
      message: "请填写限制数量",
      trigger: "blur",
    },
  ],
  delayNum: [
    {
      required: true,
      message: "请填写延期天数",
      trigger: "blur",
    },
  ],
  couponId: [
    {
      required: true,
      message: "请选择卡券",
      trigger: "blur",
    },
  ],
  noticeName: [
    {
      required: true,
      message: "请填写通知名称",
      trigger: "blur",
    },
  ],
  wechatUserIdSet: [
    {
      required: true,
      message: "请选择用户",
      trigger: "blur",
    },
  ],
  templateIdSet: [
    {
      required: true,
      message: "请选择通知",
      trigger: "blur",
    },
  ],
  // 保洁管理
  nickName: [
    {
      required: true,
      message: "请填写昵称",
      trigger: "blur",
    },
  ],
  status: [
    {
      required: true,
      message: "请选择状态",
      trigger: "blur",
    },
  ],
  wxRefundAmount: [
    {
      required: true,
      message: "请输入微信退款金额",
      trigger: "blur",
    },
  ],
  bjRefundAmount: [
    {
      required: true,
      message: "请输入本金退款金额",
      trigger: "blur",
    },
  ],
  zjRefundAmount: [
    {
      required: true,
      message: "请输入赠金退款金额",
      trigger: "blur",
    },
  ],

  refundMemberCardUseNum: [
    {
      required: true,
      message: "请填写退还次数",
      trigger: "blur",
    },
  ],
  refundPrice: [
    {
      required: true,
      message: "请填写退款金额",
      trigger: "blur",
    },
  ],
  refAmount: [
    {
      required: true,
      message: "请填写退款金额",
      trigger: "blur",
    },
  ],
  pointsRefundAmount: [
    {
      required: true,
      message: "请填收回积分数",
      trigger: "blur",
    },
  ],
  shareWriting: [
    {
      required: true,
      message: "请填写分享文案",
      trigger: "blur",
    },
  ],
  sharePic: [
    {
      required: true,
      message: "请上传分享图片",
      trigger: "blur",
    },
  ],
  advanceFlg: [
    {
      required: true,
      message: "请选择是否开启提前服务",
      trigger: "blur",
    },
  ],
  advanceContent: [
    {
      required: true,
      message: "请选择服务内容",
      trigger: "blur",
    },
  ],
  advanceStartTime: [
    {
      required: true,
      message: "请选择上班时间",
      trigger: "blur",
    },
  ],
  advanceEndTime: [
    {
      required: true,
      message: "请选择下班时间",
      trigger: "blur",
    },
  ],
  money: [
    {
      required: true,
      message: "请输入提现金额",
      trigger: "blur",
    },
  ],
  // 商户商品管理
  goodsName: [
    {
      required: true,
      message: "请填写商品名称",
      trigger: "blur",
    },
  ],
  retailPrice: [
    {
      required: true,
      message: "请填写建议售价",
      trigger: "blur",
    },
  ],
  goodsDetail: [
    {
      required: true,
      message: "请填写商品详情",
      trigger: "blur",
    },
  ],
  imageId: [
    {
      required: true,
      message: "请上传商品缩略图",
      trigger: "blur",
    },
  ],
  imageIds: [
    {
      required: true,
      message: "请上传商品主图",
      trigger: "blur",
    },
  ],
  inventoryType: [
    {
      required: true,
      message: "请选择进销损类型",
      trigger: "blur",
    },
  ],
  inventory: [
    {
      required: true,
      message: "请填写数量",
      trigger: "blur",
    },
  ],
  salesPrice: [
    {
      required: true,
      message: "请填写销售价格",
      trigger: "blur",
    },
  ],
  goodsType: [
    {
      required: true,
      message: "请选择商品分类",
      trigger: "blur",
    },
  ],
  shopGoodsId: [
    {
      required: true,
      message: "请选择商品",
      trigger: "blur",
    },
  ],
  goodsTypeName: [
    {
      required: true,
      message: "请填写分类名称",
      trigger: "blur",
    },
  ],
  deviceName: [
    {
      required: true,
      message: "请填写设备名称",
      trigger: "blur",
    },
  ],
  deviceDetails: [
    {
      required: true,
      message: "请填写设备描述",
      trigger: "blur",
    },
  ],
  dateToDate: [
    {
      required: true,
      message: "请选择开始和结束日期",
      trigger: "blur",
    },
  ],
  umsMemberId: [
    {
      required: true,
      message: "请选择预约用户",
      trigger: "blur",
    },
  ],
  packageName: [
    {
      required: true,
      message: "请输入套餐名称",
      trigger: "blur",
    },
  ],
  rechargeMoney: [
    {
      required: true,
      message: "请输入充值金额",
      trigger: "blur",
    },
  ],
  giftMoney: [
    {
      required: true,
      message: "请输入赠送金额",
      trigger: "blur",
    },
  ],
  selectedCardName: [
    {
      required: true,
      message: "请选择对应卡券",
      trigger: "change",
    },
  ],
  codeNum: [
    {
      required: true,
      message: "请输入总兑换码次数",
      trigger: "blur",
    },
  ],
  dateToDate: [
    {
      required: true,
      message: "请输兑换有效期",
      trigger: "change",
    },
  ],
  minUsePrice: [
    {
      required: true,
      message: "请输入最低使用金额",
      trigger: "blur",
    },
  ],
  deductionPrice: [
    {
      required: true,
      message: "请输入抵扣金额",
      trigger: "blur",
    },
  ],
  channelName: [
    {
      required: true,
      message: "请输入渠道名称",
      trigger: "blur",
    },
  ],
  // 弹窗通知
  popupTitle: [
    {
      required: true,
      message: "请输入弹窗标题",
      trigger: "blur",
    },
  ],
  popupContent: [
    {
      required: true,
      message: "请输入弹窗内容",
      trigger: ['blur','change'],
    },
  ],
  contentType: [
    {
      required: true,
      message: "请选择跳转类型",
      trigger: "blur",
    },
  ],
  topNo:[
    {
      required: true,
      message: "请输入排序",
      trigger: "blur",
    }
  ],
    // 工位区域管理
  planName: [
    {
      required: true,
      message: "请输入区域名称",
      trigger: "blur",
    },
  ],
  serviceId: [
    {
      required: true,
      message: "请选择归属服务内容",
      trigger: ['blur', 'change'],
    },
  ],
  fileId: [
    {
      required: true,
      message: "请上传3D俯视图",
      trigger: ['blur', 'change'],
    },
  ],
  planWidth: [
    {
      required: true,
      message: "请输入区域实际宽度",
      trigger: "blur",
    },
  ],
  seatWidth: [
    {
      required: true,
      message: "请输入工位实际宽度",
      trigger: "blur",
    },
  ],
  // 虚拟卡券
packageName:[
  {
    required: true,
    message: "请输入卡券名称",
    trigger: "blur",
  }
]

};







